<style scoped lang="less">
  .doc-home-page {
    background-color: #EFF1F7;
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 100%;
    .count {
      width: 150px;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      .num {
        color: #F4628F;
        padding-top: 30px;
        font-size: 50px;
        font-weight: 400;
      }
      .dw {
        padding-top: 30px;
      }
    }
    .title {
      font-size: 16px;
      color: #3C3F54;
      margin-bottom: 15px;
      display: flex;
    }
    .left {
      height: 100%;
      width: calc(100% - 420px);
      .top {
        padding: 20px;
        box-sizing: border-box;
      }
      .bottom {
        height: calc(100% - 230px);
        padding: 0 20px 20px 20px;
        position: relative;
      }
    }
    .right {
      width: 400px;
      height: calc(100% - 40px);
      padding: 16px;
      margin: 20px 20px 20px 0;
      background-color: #FFF;
      position: relative;
    }
  }
  .card {
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    margin-right: 50px;
    position: relative;
    background-color: #FFF;
  }
  .card-t {
    position: absolute;
    top: 30px;
    font-size: 18px;
  }
  .cards {
    display: flex;
  }
  .list {
    width: 100%;
    height: calc(100% - 0px);
    overflow-y: auto;
  }
  .d-item {
    display: flex;
    padding: 20px 0;
    margin: 0 20px;
    background: #fff;
    cursor: pointer;
    border-bottom: 1px solid #F0F3F8;
    display: flex;
    align-items: center;
    .d-n {
      width: 100px;
      text-align: right;
    }
    .d-t {
      width: calc(100% - 150px);
    }
    .d-type {
      width: 50px;
    }
  }
  .d-item:hover {
    background: 0;
  }
  .r-title {
    font-size: 16px;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 88px;
    overflow-y: auto;
    color: #3C3F54;
    margin-bottom: 15px;
    border-bottom: 1px solid rgb(203, 213, 224);
    padding-bottom: 15px;
  }
  .r-list {
    height: calc(100% - 130px);
    width: 100%;
    overflow-y:auto;
    .h-item {
      display: flex;
      padding: 8px 20px;
      justify-content: space-between;
    }
  }
  .wdb {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    color: #999;
  }
  .btm-btn {
    height: 30px;
    position: absolute;
    cursor: pointer;
    text-align: center;
    line-height: 30px;
    color: #fff;
    background: #F4628F;
    bottom: 0;
    left: 0;
    right: 0;
  }
</style>

<template>
  <div class="doc-home-page">
    <div class="left">
      <div class="top">
        <div class="title">
          <div style="margin-right: 20px;">当前未办理公文分析</div>
          <fm-date-picker v-model="year" @change="yearChange" :clearable="true" type="year" placement="bottom-end" placeholder="请选择年份" style="width: 200px" />
        </div>
        <div class="cards">
        <div class="count card">
          <div class="card-t">收文</div>
          <span class="num">{{dataList.length}}</span>
          <span class="dw" style="margin-bottom: -20px;padding-left: 5px;">条</span>
        </div>
        <div class="count card">
          <div class="card-t">发文</div>
          <span class="num">{{sendDataList.length}}</span>
          <span class="dw" style="margin-bottom: -20px;padding-left: 5px;">条</span>
        </div>
      </div>
      </div>
      <div class="bottom">
        <div class="list card">
          <div class="d-item" @click="chooseItem(item)" v-for="item in dataList" :key="item.id">
            <div class="d-type">
              收文
            </div>
            <div class="d-t">
              {{item.title}}
            </div>
            <div class="d-n">
              {{item.dbUserLength ? (item.dbUserLength + '人待办') : '未安排处理人'}}
            </div>
          </div>
          <div class="d-item" @click="chooseSendItem(item)" v-for="(item, index) in sendDataList" :key="'fw' + index">
            <div class="d-type">
              发文
            </div>
            <div class="d-t">
              {{item.title}}
            </div>
            <div class="d-n">
              {{item.msg}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right card" v-if="chooseData">
      <div class="r-title">{{chooseData.title}}</div>
      <div class="r-list" v-if="chooseData.handles.length > 0">
        <div class="h-item" style="padding-bottom: 10px;">
          <div>人员</div>
          <div>待办环节</div>
        </div>
        <div class="h-item" v-for="(item, index) in chooseData.dbHandles" :key="index">
          <div>{{item.userName}}</div>
          <div>{{item.officialDocumentHandleStepType}}</div>
        </div>
      </div>
      <div v-else class="r-list wdb">
        无待办
      </div>
      <div class="btm-btn" @click="goDetail">
        查看详情
      </div>
    </div>
    <div class="right card" v-if="chooseSendData">
      <div class="r-title">{{chooseSendData.title}}</div>
      <div class="r-list" v-if="chooseSendData.orgNames">
        <div>待签收：</div>
        <div style="padding-left: 20px;" v-for="item in chooseSendData.orgNames" :key="item">{{item.orgName + ' ' + item.userName + ' 未签收'}}</div>
      </div>
      <div class="r-list" v-else>
        <div>{{chooseSendData.statusText + '：' + chooseSendData.msg}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  officialDocumentRequest,
  officialDocumentSendRequest
} from '../../api'

export default {
  data () {
    return {
      dataList: [],
      sendDataList: [],
      chooseData: null,
      chooseSendData: null,
      year: new Date()
    }
  },
  activated () {
    this.loadData()
    this.loadDataSend()
  },
  methods: {
    yearChange () {
      this.loadData()
      this.loadDataSend()
    },
    chooseItem (item) {
      this.chooseSendData = null
      this.chooseData = this.chooseData === item ? null : item
    },
    chooseSendItem (item) {
      this.chooseData = null
      this.chooseSendData = this.chooseSendData === item ? null : item
    },
    goDetail () {
      this.$router.push({
        name: 'hrms.doc.detail',
        query: {
          id: this.chooseData.id,
          type: this.chooseData.type,
          handle: this.chooseData.isHandle ? 1 : 0
        }
      })
    },
    async loadData () {
      let datas = []
      let parm = {
        status: 'doing'
      }
      if (this.year) {
        parm.getYear = this.year.getFullYear()
      }
      if (this.$authFunsProxy.get || this.$authFunsProxy.getMy) {
        datas = await officialDocumentRequest.getDetail(parm)
      }
      datas.forEach(v => {
        v.dbNum = 0
        v.allNum = 0
        v.dbUserIds = []
        v.allUserIds = []
        v.handles = []
        // v.nullSteps = false
        v.steps = v.steps || []
        v.steps.forEach(s => {
          s.handles = s.handles || []
          // if (s.handles.length === 0) {
          //   v.nullSteps = true
          // }
          v.allNum += s.handles.length
          v.dbNum += s.handles.filter(h => h.status === 'wait').length
          s.handles.forEach(h => {
            if (!v.allUserIds.includes(h.userId)) {
              v.allUserIds.push(h.userId)
            }
            if (h.status === 'wait' && !v.dbUserIds.includes(h.userId)) {
              v.dbUserIds.push(h.userId)
            }
          })
          v.handles = v.handles.concat(s.handles)
        })
        v.dbHandles = v.handles.filter(v1 => v1.status === 'wait')
        v.isHandle = v.allUserIds.includes(this.$store.getters.currentUserId)
        v.dbUserLength = v.dbUserIds.length
      })
      datas = datas.filter(v => v.dbUserIds.length > 0)
      if (!this.$authFunsProxy.get && this.$authFunsProxy.getMy) {
        datas = datas.filter(v => v.isHandle)
      }
      this.dataList = datas
    },
    async loadDataSend () {
      let sendDatas = []
      let handDatas = []
      let sendYear = this.year ? this.year.getFullYear() : null
      let parm2 = {
        status: 'wait',
        officialDocumentSendStatus: 'end'
      }
      if (sendYear) {
        parm2.sendYear = sendYear
      }
      if (this.$authFunsProxy.get) {
        let parm = {
          statusStr: 'wait_1,wait_2'
        }
        if (sendYear) {
          parm.sendYear = sendYear
        }
        sendDatas = await officialDocumentSendRequest.get(parm)
        handDatas = await officialDocumentSendRequest.getHandle(parm2)
      } else if (this.$authFunsProxy.getMy) {
        let parm = {
          isUpcoming: '1'
        }
        if (sendYear) {
          parm.sendYear = sendYear
        }
        sendDatas = await officialDocumentSendRequest.getByAuth(parm)
        handDatas = await officialDocumentSendRequest.getMyHandle(parm2)
      }
      sendDatas = sendDatas.filter(v => v.status != 'draw')
      sendDatas.forEach(v => v.msg = v.status === 'wait_1' ?  '待' + v.leadUserName + '审批' : '待' + v.deanUserName + '审批')
      let hMap = {}
      handDatas.forEach(v => {
        if (!hMap[v.officialDocumentSendId]) {
          v.orgNames = [{
            orgName: v.orgName,
            userName: v.userName
          }]
          hMap[v.officialDocumentSendId] = v
        } else {
          hMap[v.officialDocumentSendId].orgNames.push({
            orgName: v.orgName,
            userName: v.userName
          })
        }
      })
      Object.keys(hMap).forEach(key => {
        let v = hMap[key]
        sendDatas.push({
          id: v.officialDocumentSendId,
          title: v.officialDocumentTitle,
          orgNames: v.orgNames,
          msg: v.orgNames.length + '人待签收'
        })
      })
      this.sendDataList = sendDatas
    }
  },
}
</script>
